import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import '../../../utils/styles/Mire.css';
import { bindActionCreators } from 'redux';
import * as authenticationActions from '../../../actions/authentication';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getLoginErrors, getLoginInformation, getChallengeInformation, getResponseErrors, getLoggedInStatus } from '../../../selectors/authentication';
import BeforeLogin from './BeforeLogin';
import AfterLogin from './AfterLogin';
import ConventionBlocked from './ConventionBlocked';
import Dialog from '../../../widgets/Dialog/index.js';

const styles = theme => ({
    actions: {
        justifyContent: 'center'
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
});


const Component = ({ onClose, open, actions, error, loginInformation, challengeInformation, responseError, isLoggedIn }) => {
    return (
        <Dialog action={onClose} open={open} title="SIGN IN">

            {!loginInformation ?
                <BeforeLogin onClose={onClose} error={error} actions={actions} />
                :
              
                responseError !== "KO_BLOCKED" ?
                <AfterLogin actions={actions}
                    loginInformation={loginInformation}
                    challengeInformation={challengeInformation}
                    responseError={responseError}
                    isLoggedIn={isLoggedIn}
                    onClose={onClose}
                /> :
                <ConventionBlocked responseError={responseError} />
            }
        </Dialog>
    );

}
const actions = {
    validateLogin: authenticationActions.validateLogin,
    getChallenge: authenticationActions.getChallenge,
    answerChallenge: authenticationActions.answerChallenge
};


const mapStateToProps = createStructuredSelector({
    error: getLoginErrors,
    loginInformation: getLoginInformation,
    challengeInformation: getChallengeInformation,
    responseError: getResponseErrors,
    isLoggedIn: getLoggedInStatus
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});


export const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps));
export default enhance(Component)
