import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import { FormattedHTMLMessage } from 'react-intl';
import AccountBlocked from '../../../../widgets/AccountBlocked';
import Login from '../../../../widgets/Login';
import ConventionPending from '../../../../widgets/ConventionPending';



const Component = ({ error, actions,onClose }) => {

    const getComponent = (response) => {
        const CONVENTION_BLOCKED = 'hia.convention.blocked';
        const CONVENTION_PENDING = 'hia.convention.pending';
        const CONVENTION_NOT_FOUND='hia.account.not.found'
        if (response === CONVENTION_BLOCKED) {
            return <AccountBlocked error={response} />
        }
        if (response === CONVENTION_PENDING||response===CONVENTION_NOT_FOUND) {
            return <ConventionPending onClose={onClose} />
        }
        else {
            return <Login error={response} actions={actions} />
        }
    }

    return (
        <Grid container justify="center">
            <Grid item xs={12}>
                <DialogContent>
                    <Grid item xs={12}>
                        <Grid container justify="center">
                            {error &&
                                <Typography variant="body2" style={{ color: "#28558A", textAlign:"center" }}>
                                    <FormattedHTMLMessage id={error} />
                                </Typography>
                            }
                            &nbsp;
                        </Grid>
                    </Grid>
                </DialogContent>
            </Grid>
            <Grid item xs={12}>
                {getComponent(error)}
            </Grid>
        </Grid>
    )
}

export default Component;