import React from 'react';
import { compose } from 'recompose';
import Grid from '@material-ui/core/Grid';
import '../../../utils/styles/Layout.css';

const Component = ({ classes, isLoggedIn }) => {
    return (
        <Grid container>     
            <div onClick={() => { window.location.href = 'https://hiasecure.com/'; }} id="hia_back_btn">
                <img src="back-arrow.png" alt="Icon retour"/>
            </div> 
            <Grid item sm={12}>
            {!isLoggedIn === true ?
                <div id='hia_btn'>
                    <div id="hia_logo">
                        <img src="Logo-Blanc-HIA-En11.png" alt="Logo HIASecure"/>
                    </div>
                    <div className="btn" onClick={() => { window.location.href = '/connect?redirectTo=/admin'; }}>Se connecter</div>
                    <div className="hia_demande_demo" onClick={() => { window.location.href = 'https://hiasecure.com/contact/'; }}>Demander un accès démo</div>
                </div> : null
            }
            </Grid>
        </Grid>
    )
}

export const enhance = compose();
export default enhance(Component)