import { SET_LOGIN_INFORMATION, SET_LOGIN_ERROR, SET_CHALLENGE_INFORMATION, SET_RESPONSE_ERROR, SET_LOGGED_IN } from "../actions/authentication";


export default (state = { isLoggedIn: false }, action) => {
    switch (action.type) {
        case SET_LOGIN_INFORMATION:
            return { ...state, loginInformations: action.data }
        case SET_CHALLENGE_INFORMATION: {
            return { ...state, challengeInformations: action.data }
        }
        case SET_LOGIN_ERROR:
            return { ...state, loginError: action.data }
        case SET_RESPONSE_ERROR: {
            return { ...state, responseError: action.data }
        }
        case SET_LOGGED_IN: {
            return { ...state, isLoggedIn: true }
        }
        default:
            return state;
    }
};
