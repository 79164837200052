import React from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { Form } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Grid';
import AccountBlocked from '../../../../widgets/AccountBlocked';





const Component = ({ responseError }) => {

    const onSubmit = () => {

    };
    return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>

                                <Grid container justify="center">
                                    <Typography variant="body2" style={{ color: "#28558A" }}>
                                        <FormattedMessage id="challenge.invalidanswer" />
                                    </Typography>
                                </Grid>
                                &nbsp;
                            </Grid>
                            <Grid item xs={12}>

                                <Grid container justify="center">
                                    <Typography variant="body2" style={{ color: "#28558A" }}>
                                        <FormattedMessage id="challenge.no.more.tries" />
                                    </Typography>
                                </Grid>
                                &nbsp;
                            </Grid>

                            <Grid item xs={12}>

                                <Grid container justify="center">
                                    <Typography variant="body2" style={{ color: "#28558A" }}>
                                        <FormattedMessage id='hia.convention.blocked' />
                                    </Typography>
                                </Grid>
                                &nbsp;
                            </Grid>
                        </Grid>

                        <AccountBlocked />
                    </DialogContent>
                </form>
            )}
        />
    )
}



export const enhance = compose(
)
export default enhance(Component);