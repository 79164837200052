import React from 'react';
import Mire from './Mire';
import { compose, withStateHandlers } from 'recompose';

const Component = ({ onClose, isOpen }) => {
    return (
        <div>
            <Mire open={isOpen} onClose={onClose}  />
        </div>
    )
}
const withDialog = withStateHandlers(() => ({ isOpen: true }), {
    onClose: () => value => ({ isOpen: !value }),
})

export const enhance = compose(
    withDialog
);

export default enhance(Component);