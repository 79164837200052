import axios from 'axios';
import qs from 'querystring'
import { saveToken } from '../utils/sessionStorage';
import { CLIENT_ID, CLIENT_SECRET, GRANT_TYPE } from '../utils/tokenConfig';






export const SET_TOKEN = 'SET_TOKEN';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const LOGOUT = 'LOGOUT';
export const SET_ERRORS = 'SET_ERRORS'


export const setToken = (data) => ({ type: SET_TOKEN, data });
export const setErrors = error => ({ type: SET_ERRORS, error })

export const getApplicationToken = () => (dispatch) => {


    const params = {
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        grant_type: GRANT_TYPE
    }

    axios.post("https://secureclient.preprod.hia.datafab.cloud/zuul/hia-oauth2/oauth/token", qs.stringify(params), {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },

    }).then((response) => {
        if (response.status === 200) {
            dispatch(setToken(response.data))
            saveToken(response.data)

        }
    }).catch(function (error) {

        dispatch(setErrors(error.response.data.error))

    })
}