import React from 'react';
import Layout from './Layout';
import { Route } from 'react-router';
import Connection from '../Connection';
import { compose } from 'recompose';
import Grid from '@material-ui/core/Grid';
import Admin from '../Admin';
import HealthCheck from '../../pages/HealthCheck'
import { getLoggedInStatus } from '../../selectors/authentication';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

const App = ({ isLoggedIn }) => {

    return (
        <Grid container>
           <Layout isLoggedIn={isLoggedIn} />
            <Grid item sm={12} style={{minHeight: 1, maxWidth:'100%' }}>
                <Route exact path='/connect' component={Connection} />
                <Route exact path='/admin' component={Admin} />
                <Route exact path='/info' component={HealthCheck} />
            </Grid>
        </Grid>

    );
}
const mapStateToProps = createStructuredSelector({
    isLoggedIn: getLoggedInStatus
})
export const enhance = compose(
    connect(mapStateToProps, null)
)
export default enhance(App);
