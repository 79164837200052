import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { compose, lifecycle, withStateHandlers } from 'recompose';
import { Form, Field } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Grid';
import TextField from '../../../../forms/TextField';
import Timer from '../../../../widgets/Timer';
import DialogActions from '../../../../widgets/Dialog/DialogFooter';
import { NumberOfTry } from '../../../../utils';
import { withStyles } from '@material-ui/core';
import { Spacer } from '../../../../widgets/Spacer';



const styles = () => ({
    terms: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
})
const Component = ({ actions, loginInformation, responseError, challengeInformation, saveResponse, response, classes }) => {

    const onSubmit = (values, form) => {
        actions.answerChallenge(loginInformation.challengeId, loginInformation.userId, values.response);
        saveResponse(values.response)
        form.reset();
    };
    const openWindow = () => {
        let id = (new Date()).getTime();
        const myWindow = window.open(+ '?printerFriendly=true', id, "toolbar=1,scrollbars=1,location=no,statusbar=0,menubar=1,resizable=1,width=300,height=200");
        myWindow.document.write(`<h2>Your challenge</h2><div><p>${challengeInformation.challenge}</p></div>`);
        myWindow.focus()

    }
    return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, pristine, form, invalid, values }) => (
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Grid container>

                            <Grid item xs={12}>
                                {responseError ?
                                    <Grid container justify="center" >
                                        <Grid item xs={12}>
                                            <Grid container justify="center" >
                                                <Typography variant="body2" style={{ color: "red" }}>
                                                    <FormattedMessage id="challenge.invalidanswer" />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container justify="center" >
                                                <Typography variant="body2" style={{ color: "#28558A" }}>
                                                    {response}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        &nbsp;
                                    </Grid> :
                                    <Grid container justify="center">
                                        <Typography variant="body2" style={{ color: "#28558A" }}>
                                            Your response
                                        </Typography>

                                        &nbsp;
                                    </Grid>}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {responseError &&
                                <Grid container justify="center">
                                    <Typography variant="body2" style={{ color: "#28558A" }}>
                                        <FormattedMessage id={NumberOfTry(challengeInformation.context.countAttemps)} values={{ attempts: challengeInformation.context.countAttemps }} />
                                    </Typography>
                                    &nbsp;
                                    </Grid>}

                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    //validate={required('required')}
                                    name="response"
                                    type="text"
                                    label="Response"
                                    autoFocus
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                />
                                &nbsp;
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justify="center">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={pristine || invalid}
                                    >
                                        OK
                                             </Button>
                                </Grid>
                                &nbsp;
                                    </Grid>
                            <Grid item xs={12}>
                                {!responseError &&
                                    <Grid container justify="flex-start">
                                        <Grid item xs={12}>
                                            <Grid container justify="flex-start">
                                                <Typography variant="body2" >
                                                    <FormattedMessage id="require.new.convention" />
                                                </Typography>
                                                <Spacer size="SMALL" />
                                                <Typography variant="body2" className={classes.terms} >
                                                    <FormattedMessage id="click.here" />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container justify="flex-start">
                                                <Typography variant="body2" >
                                                    <FormattedMessage id="challenge.not.received" />
                                                </Typography>
                                                <Spacer size="SMALL" />
                                                <Typography onClick={() => openWindow()} variant="body2" className={classes.terms} >
                                                    <FormattedMessage id="click.here" />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>}


                            </Grid>

                        </Grid>
                    </DialogContent>

                    <DialogActions >
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container justify="center">
                                    <Timer value={120} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogActions>


                </form>
            )}
        />
    )
}

const loadComponents = lifecycle({
    componentDidMount() {
        const { actions, loginInformation } = this.props
        actions.getChallenge(loginInformation.challengeId, loginInformation.userId)
    },
    componentWillReceiveProps(nextProps) {
        const { challengeInformation, history, onClose, match } = this.props

        if (nextProps.challengeInformation !== challengeInformation) {
            if (!nextProps.responseError) {
                try {
                    let id = (new Date()).getTime();
                    const myWindow = window.open(match.url + '?printerFriendly=true', id, "toolbar=1,scrollbars=1,location=no,statusbar=0,menubar=1,resizable=1,width=300,height=200");
                    myWindow.document.write(`<h2>Your challenge</h2><div><p>${nextProps.challengeInformation.challenge}</p></div>`);
                    myWindow.focus()
                }
                catch (err) {
                    console.log('aloooo')
                }
            }


        }
        if (nextProps.isLoggedIn === true) {
            onClose(true)
            history.push('/admin')
        }
    }
},
);
const withResponse = withStateHandlers(() => ({ response: undefined }), {
    saveResponse: () => value => ({ response: value }),
});

export const enhance = compose(
    withStyles(styles),
    withResponse,
    withRouter,
    loadComponents,
)
export default enhance(Component);