import React from 'react';
import { compose } from 'recompose';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { Field, Form } from 'react-final-form';
import TextField from '../forms/TextField/';
import { Grid } from '@material-ui/core';

const Component = ({ error,actions }) => {
    const onSubmit = values => {
        actions.validateLogin(values.login)
    }; return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, pristine, form, invalid, values }) => (
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Grid container justify="center">
                            <Grid item xs={12}>
                                <Grid container justify="center">
                                    <Field
                                        component={TextField}
                                        //validate={required('required')}
                                        name="login"
                                        type="text"
                                        label="Login"
                                        autoFocus
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        
                                    />
                                    &nbsp;
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justify="center">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={pristine || invalid}>
                                        OK
                                    </Button>
                                    &nbsp;
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>

                </form >
            )}
        />

    )
}

export const enhance = compose();
export default enhance(Component);