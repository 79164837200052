import React from 'react';
import { FormattedMessage } from 'react-intl';

class Example extends React.Component {
    constructor() {
        super();
        this.state = { time: {}, seconds: 130 };
        this.timer = 0;
        this._isMounted = false;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        if (minutes < 10) {
            minutes = `0${minutes}`
        }
        if (seconds < 10) {
            seconds = `0${seconds}`
        }
        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    componentDidMount() {
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({ time: timeLeftVar });
            this.startTimer()
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    startTimer() {
        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds === 0) {
            clearInterval(this.timer);
        }
    }

    render() {
        return (
            <div>
                <FormattedMessage id="time.left" /> <span style={{color:"#28558A",fontWeight:"bold"}}>{this.state.time.m} : {this.state.time.s}</span>
            </div>
        );
    }
}

export default Example;